import styled from "@emotion/styled";

const DemoBadge = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999900;
  pointer-events: none;
  background: var(--color-danger);
  color: var(--color-white);
  padding: 0.25rem 0.4rem 0.1rem;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 0 0 0.5rem 0;
  opacity: 0.9;
`;

export default DemoBadge;
