import type { TaskStatus } from "@9amhealth/wcl";
import type { FC, ReactNode } from "react";
import React, { isValidElement } from "react";

interface ButtonAsObject {
  label: string;
  onClick?: () => void;
}

export interface Step {
  status: TaskStatus;
  title: ReactNode | string;
  subtitle?: ReactNode | string;
  content?: ReactNode | string;
  show?: () => boolean;
}

export interface NextStepsCardProps {
  steps?: Step[];
  button?: ButtonAsObject | ReactNode;
}

const NextStepsCard: FC<NextStepsCardProps> = (props) => {
  const renderButtonObject = (button: ButtonAsObject) => {
    return (
      <>
        <nine-spacer s="xs"></nine-spacer>
        <div className="center">
          <nine-button onClick={button.onClick}>{button.label}</nine-button>
        </div>
        <nine-spacer s="md"></nine-spacer>
      </>
    );
  };

  const renderButtonAsNode = (button: ReactNode) => {
    return (
      <>
        <nine-spacer s="xs"></nine-spacer>
        <div className="center">{button}</div>
        <nine-spacer s="md"></nine-spacer>
      </>
    );
  };

  return (
    <nine-card
      slot="content"
      grow="true"
      variant="row"
      moreContent="true"
      alwaysShowMore="true"
      no-title
      large-radius
      custom-padding-large
      style={{
        maxWidth: "calc(100vw - var(--space-md) * 2)",
        margin: "0 auto"
      }}
    >
      <div slot="more">
        {props.steps && (
          <nine-wrapper>
            {props.steps.map((step, i) =>
              (step.show ? step.show() : true) ? (
                <nine-task status={step.status} key={String(i)}>
                  <div slot="title">{step.title}</div>
                  {step.subtitle && <div slot="subtitle">{step.subtitle}</div>}
                  {step.content && <div slot="content">{step.content}</div>}
                </nine-task>
              ) : null
            )}
          </nine-wrapper>
        )}

        {props.button &&
          !isValidElement(props.button) &&
          renderButtonObject(props.button as ButtonAsObject)}
        {props.button &&
          isValidElement(props.button) &&
          renderButtonAsNode(props.button as ReactNode)}
      </div>
    </nine-card>
  );
};

export default NextStepsCard;
